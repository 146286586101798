body {
  background-color: #f5f3f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
}