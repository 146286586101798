.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.p-inputnumber , .p-inputtext{
  width: 100%;
}
